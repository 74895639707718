<template>
    <ddcard class="box-card" :collapsed="true" :inactive="!active">
        <div slot="title" class="clearfix">
            <el-switch v-model="active" :active-value="1" :inactive-value="0" />
            <span style="margin-left: 20px;">FGO</span>
        </div>

        <Box type="info" :active="true" style="margin-top: 0; margin-bottom: 10px;">
            <div slot="box-body">
                <ul style="font-size: 12px;">
                    <li>Pentru a utiliza acest serviciu este nevoie sa aveti cel putin abonamentul <b>FGO PRO</b></li>
                    <li>Token-ul se poate obtine Setari > Utilizatori > User API</li>
                </ul>
            </div>
        </Box>

        <el-row v-if="active" :gutter="30">
            <el-col :xs="24" :md="12">
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24">
                        <el-form-item :label="$t('sales.billing.token')" prop="fgo.token">
                            <el-input v-model="token"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.product_reference')" prop="fgo.product_reference">
                            <el-select class="fullWidth" v-model="product_reference" filterable>
                                <el-option
                                    value="products_model"
                                    :label="$t('sales.billing.products_model')"
                                ></el-option>
                                <el-option value="vendor_ext_id" :label="$t('sales.billing.vendor_ext_id')"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.invoice_serie')" prop="fgo.invoice_serie">
                            <el-input v-model="invoice_serie"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8">
                        <el-form-item :label="$t('sales.billing.proforma_serie')" prop="fgo.proforma_serie">
                            <el-input v-model="proforma_serie"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-col>

            <el-col :xs="24" :md="12">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.warehouse')" prop="fgo.warehouse">
                            <el-input v-model="warehouse"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_code')" prop="fgo.shipping_code">
                            <el-input v-model="shipping_code"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item :label="$t('sales.billing.shipping_box_code')" prop="fgo.shipping_box_code">
                            <el-input v-model="shipping_box_code"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-checkbox
                            v-model="issue_payment"
                            :true-label="1"
                            :false-label="0"
                            :label="$t('sales.billing.issue_payment')"
                        />
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </ddcard>
</template>

<script>
import { sync } from 'vuex-pathify';

export default {
    name: 'FGO',
    computed: {
        active: sync('account/billing@fgo.active'),
        token: sync('account/billing@fgo.token'),
        product_reference: sync('account/billing@fgo.product_reference'),
        invoice_serie: sync('account/billing@fgo.invoice_serie'),
        proforma_serie: sync('account/billing@fgo.proforma_serie'),
        warehouse: sync('account/billing@fgo.warehouse'),
        shipping_code: sync('account/billing@fgo.shipping_code'),
        shipping_box_code: sync('account/billing@fgo.shipping_box_code'),
        issue_payment: sync('account/billing@fgo.issue_payment')
    },
    components: {
        ddcard: () => import('@/components/DDCard'),
        Box: () => import('vue-info-box-element')
    }
};
</script>
